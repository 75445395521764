import React, { Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { ReactComponent as OrigamiServicesGlyph } from '../images/Glyph.svg';
import { NavLink as Nlink } from 'react-router-dom';

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Container style={{padding: "0"}} fluid>
                    <Row className="Footer" noGutters>
                        <Col>
                            <Row noGutters>
                                <Col>
                                    <OrigamiServicesGlyph onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} className="OrigamiServicesFooterLogo" />
                                </Col>
                            </Row>
                            <Row className="FooterNav" noGutters>
                                <Col xs={12} sm={2} md={2}>
                                    <a onClick={() => { this.props.approachRef.current.scrollIntoView({ behavior: "smooth" }) }} className="FooterText">Approach</a>
                                </Col>
                                <Col xs={12} sm={2} md={2}>
                                    <a onClick={() => { this.props.servicesRef.current.scrollIntoView({ behavior: "smooth" }) }} className="FooterText">Services</a>
                                </Col>
                                <Col xs={12} sm={2} md={2}>
                                    <a onClick={() => { this.props.benefitsRef.current.scrollIntoView({ behavior: "smooth" }) }} className="FooterText">Benefits</a>
                                </Col>
                            </Row>
                            <hr
                                style={{
                                    marginTop: "3%",
                                    color: "#FFFFFF",
                                    background: "#FFFFFF",
                                    height: "1px",
                                    opacity: "0.2"
                                }}
                            />
                            <Row noGutters>
                                <Col className="FooterBranding">
                                    &copy; 2021 Origami Services Pty Ltd.
                                    {/* <br />ABN */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
export default Footer;