import './App.css';
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'react-bootstrap';

import Home from './pages/Home';

import Footer from './components/Footer';
import StickyNav from './components/StickyNav';
import './css/app.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.approachRef = React.createRef();
    this.servicesRef = React.createRef();
    this.benefitsRef = React.createRef();
  }
  render() {
    return (
      <Fragment>
        <Container className='WebPage' fluid>
          <StickyNav
            approachRef={this.approachRef}
            servicesRef={this.servicesRef}
            benefitsRef={this.benefitsRef}
          />
          <Home
            approachRef={this.approachRef}
            servicesRef={this.servicesRef}
            benefitsRef={this.benefitsRef}
          />
          <Footer
            approachRef={this.approachRef}
            servicesRef={this.servicesRef}
            benefitsRef={this.benefitsRef}
          />
        </Container>
      </Fragment>
    );
  }
}

export default App;
