import React, { Fragment } from "react";

import { ReactComponent as OrigamiServicesGlyph } from '../images/Glyph.svg';

import { Container, Row, Image, Col, Button, Nav, Navbar } from 'react-bootstrap';
import { NavLink as Nlink } from 'react-router-dom';
import '../css/stickyNav.css';
import constants from '../utils/constants';

class StickyNav extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Container className={"StickyNavContainer"} fluid>
                    <Row noGutters>
                        <Col xs={6} sm= {6} md= {6}>
                            <OrigamiServicesGlyph onClick={() => {window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}} className="OrigamiServicesLogo" />
                        </Col>
                        <Col xs={6} sm= {6} md= {6}>
                            <Nav className="NavItems" style={{float: "right"}}>
                                <a className="NavBar-Item" activeClassName="NavBar-Item-Active" onClick={() => {this.props.approachRef.current.scrollIntoView({ behavior: "smooth" })}}>Approach</a>
                                <a className="NavBar-Item" activeClassName="NavBar-Item-Active" onClick={() => {this.props.servicesRef.current.scrollIntoView({ behavior: "smooth" })}}>Services</a>
                                <a className="NavBar-Item" activeClassName="NavBar-Item-Active" onClick={() => {this.props.benefitsRef.current.scrollIntoView({ behavior: "smooth" })}}>Benefits</a>
                            </Nav>
                        </Col>
                    </Row>
                </Container>

            </Fragment>
        )
    }
}

export default StickyNav;