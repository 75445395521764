import React, { Fragment } from "react";
import { Container, Row, Image, Col } from "react-bootstrap";
import "../css/home.css";

import backgroundLeft from "../images/BackgroundLeft.jpg";
import sideImage from "../images/SideImage.jpg";

import { ReactComponent as SVG1 } from "../images/1.svg";
import { ReactComponent as SVG2 } from "../images/2.svg";
import { ReactComponent as SVG3 } from "../images/3.svg";
import { ReactComponent as SVG4 } from "../images/4.svg";
import { ReactComponent as SVG5 } from "../images/5.svg";
import { ReactComponent as SVG6 } from "../images/6.svg";
import { ReactComponent as SVG7 } from "../images/7.svg";
import { ReactComponent as SVG8 } from "../images/8.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faConciergeBell,
  faBolt,
  faMicrochip,
  faBuilding,
  faLightbulb,
  faDraftingCompass,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
// import homeView02 from '../images/HomeView02.jpg';
// import homeView03 from '../images/HomeView03.jpg';

// import homeExterior03 from '../images/HomeExterior03.jpg';
// import homeExterior02 from '../images/HomeExterior02.jpg';
// import homeInterior06 from '../images/HomeInterior06.jpg';
// import homeInterior01 from '../images/HomeInterior01.jpg';
// import homeOverhead02 from '../images/HomeOverhead02.jpg';

import constants from "../utils/constants";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDesktop: false,
      isTablet: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this.setState({
      isDesktop: window.matchMedia(
        "(min-width: " + constants.desktopWidth + "px)"
      ).matches,
      isTablet: window.matchMedia(
        "(min-width: " +
          constants.tabletWidth +
          "px) and (max-width: " +
          (constants.desktopWidth - 1) +
          "px)"
      ).matches,
    });

    window.addEventListener("resize", () => {
      this.setState({
        isDesktop: window.matchMedia(
          "(min-width: " + constants.desktopWidth + "px)"
        ).matches,
        isTablet: window.matchMedia(
          "(min-width: " +
            constants.tabletWidth +
            "px) and (max-width: " +
            (constants.desktopWidth - 1) +
            "px)"
        ).matches,
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.setState({
        isDesktop: window.matchMedia(
          "(min-width: " + constants.desktopWidth + "px)"
        ).matches,
        isTablet: window.matchMedia(
          "(min-width: " +
            constants.tabletWidth +
            "px) and (max-width: " +
            (constants.desktopWidth - 1) +
            "px)"
        ).matches,
      });
    });
  }

  render() {
    const team = this.teamMembers;
    return (
      <Fragment>
        {this.state.isDesktop ? (
          <Fragment>
            <Container
              className="HomeDesktopContent"
              style={{ backgroundColor: "#F4F5F7" }}
              fluid
            >
              <Image src={backgroundLeft} className="HomeSideImage" />
              <Row className="HomeCTA" noGutters>
                <Col xs={6} sm={6} md={6}>
                  <Row noGutters>
                    <Col className="HomeHeading">Origami</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeSubtitle">
                      Turning spaces into art in the simplest way possible
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} sm={6} md={6} />
              </Row>
            </Container>
            <Container
              className="HomeDesktopContent"
              ref={this.props.approachRef}
              fluid
            >
              <Row noGutters>
                <Col className="HomeInformationHeading">Our Approach</Col>
              </Row>
              <Row className="ApproachSection" noGutters>
                <Col>
                  <Row noGutters>
                    <Col className="HomeInformationIcon">
                      <SVG1 />
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformation">Scope</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformationText">
                      Fleshing out all aspects of your property development
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row noGutters>
                    <Col className="HomeInformationIcon">
                      <SVG2 />
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformation">Design</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformationText">
                      Working with you to design the best use of your space
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row noGutters>
                    <Col className="HomeInformationIcon">
                      <SVG3 />
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformation">Execute</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformationText">
                      Project management with military precision
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row noGutters>
                    <Col className="HomeInformationIcon">
                      <SVG4 />
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformation">Handover</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="HomeInformationText">
                      Delivery of the masterpiece
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container
              className="HomeDesktopContent"
              style={{ backgroundColor: "#F4F5F7" }}
              fluid
              ref={this.props.servicesRef}
            >
              <Row noGutters>
                <Col className="HomeInformationHeading">Services we offer</Col>
              </Row>
              <Row className="BenefitsSection" noGutters>
                <Col xs={6} sm={6} md={6}>
                  <Image src={sideImage} className="HomeImage" />
                </Col>
                <Col xs={5} sm={5} md={5}>
                  <Row noGutters>
                    <Col className="ServicesHeading">
                      &bull; Licensed Builder
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesText">
                      Registered Domestic Builders with years of experience
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesHeading">&bull; Mechanical</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesText">
                      Bespoke HVAC solutions for builders and residential
                      customers with a focus on sustainability
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesHeading">&bull; Town Planning</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesText">
                      Approvals of your projects
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesHeading">&bull; Demolition</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesText">
                      Rapid removal and recycling of old-world structures to
                      make way for the new
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesHeading">&bull; Landscaping</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ServicesText">
                      An engineered approach to foliage
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} sm={1} md={1} />
              </Row>
              <Row noGutters className="BenefitsSection">
                <Col className="HomeInformationHeading2">
                  Mechanical Services
                </Col>
              </Row>
              <Row noGutters className="BenefitsSection">
                <Col className="BenefitsText">
                  At Origami, we believe that everyone should live healthy,
                  comfortable lives in harmony with the environment. To achieve
                  this, we craft tailored heating, cooling and ventilation
                  solutions for each customer's unique requirements. We
                  specialise in creating beautiful, sustainable spaces using
                  cutting-edge green technologies.
                </Col>
              </Row>
            </Container>
            <Container
              className="HomeDesktopContent"
              fluid
              ref={this.props.benefitsRef}
            >
              <Row className="BenefitsSection" noGutters>
                <Col>
                  <Row noGutters>
                    <Col className="HomeInformationHeading2">
                      Benefits of working
                      <br />
                      with us
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col>
                      <Row className="BenefitsPoint" noGutters>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          className="HomeInformationIcon2"
                        >
                          <SVG5 />
                        </Col>
                        <Col xs={10} sm={10} md={10}>
                          <Row noGutters>
                            <Col className="BenefitsHeading">Rapid</Col>
                          </Row>
                          <Row noGutters>
                            <Col className="BenefitsText">
                              We build using rapid construction technologies
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="BenefitsPoint" noGutters>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          className="HomeInformationIcon2"
                        >
                          <SVG6 />
                        </Col>
                        <Col xs={10} sm={10} md={10}>
                          <Row noGutters>
                            <Col className="BenefitsHeading">
                              Technology-focused
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col className="BenefitsText">
                              Bespoke software as standard in all our projects
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="BenefitsPoint" noGutters>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          className="HomeInformationIcon2"
                        >
                          <SVG7 />
                        </Col>
                        <Col xs={10} sm={10} md={10}>
                          <Row noGutters>
                            <Col className="BenefitsHeading">Full Spectrum</Col>
                          </Row>
                          <Row noGutters>
                            <Col className="BenefitsText">
                              One destination to handle your project from
                              end-to-end
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="BenefitsPoint" noGutters>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          className="HomeInformationIcon2"
                        >
                          <SVG8 />
                        </Col>
                        <Col xs={10} sm={10} md={10}>
                          <Row noGutters>
                            <Col className="BenefitsHeading">Payment Plans</Col>
                          </Row>
                          <Row noGutters>
                            <Col className="BenefitsText">
                              We reduce barriers to build by working with you to
                              structure payment plans so you can build more,
                              faster
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Fragment>
            {this.state.isTablet ? (
              <Fragment>
                <Container
                  className="HomeTabletContent"
                  style={{ backgroundColor: "#F4F5F7" }}
                  fluid
                >
                  <Image src={backgroundLeft} className="HomeSideImage" />
                  <Row className="HomeCTA" noGutters>
                    <Col xs={8} sm={8} md={8}>
                      <Row noGutters>
                        <Col className="HomeHeading">Origami</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeSubtitle">
                          Turning spaces into art in the simplest way possible
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4} sm={4} md={4}></Col>
                  </Row>
                </Container>
                <Container
                  className="HomeTabletContentStretch"
                  fluid
                  ref={this.props.approachRef}
                >
                  <Row noGutters>
                    <Col className="HomeInformationHeading">Our Approach</Col>
                  </Row>
                  <Row className="ApproachSection" noGutters>
                    <Col>
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG1 />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Scope</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Fleshing out all aspects of your property development
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG2 />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Design</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Working with you to design the best use of your space
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG3 />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Execute</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Project management with military precision
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG4 />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Handover</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Delivery of the masterpiece
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
                <Container
                  className="HomeTabletContentStretch"
                  style={{ backgroundColor: "#F4F5F7" }}
                  fluid
                  ref={this.props.servicesRef}
                >
                  <Row noGutters>
                    <Col className="HomeInformationHeading">
                      Services we offer
                    </Col>
                  </Row>
                  <Row className="BenefitsSection" noGutters>
                    <Col xs={6} sm={6} md={6}>
                      <Image src={sideImage} className="HomeImage" />
                    </Col>
                    <Col xs={5} sm={5} md={5}>
                      <Row noGutters>
                        <Col className="ServicesHeading">
                          &bull; Licensed Builder
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Registered Domestic Builders with years of experience
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">&bull; Mechanical</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Bespoke HVAC solutions for builders and residential
                          customers with a focus on sustainability
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">
                          &bull; Town Planning
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Approvals of your projects
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">&bull; Demolition</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Rapid removal and recycling of old-world structures to
                          make way for the new
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">
                          &bull; Landscaping
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          An engineered approach to foliage
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} />
                  </Row>
                  <Row noGutters className="BenefitsSection">
                    <Col className="HomeInformationHeading2">
                      Mechanical Services
                    </Col>
                  </Row>
                  <Row noGutters className="BenefitsSection">
                    <Col className="BenefitsText">
                      At Origami, we believe that everyone should live healthy,
                      comfortable lives in harmony with the environment. To
                      achieve this, we craft tailored heating, cooling and
                      ventilation solutions for each customer's unique
                      requirements. We specialise in creating beautiful,
                      sustainable spaces using cutting-edge green technologies.
                    </Col>
                  </Row>
                </Container>
                <Container
                  className="HomeTabletContentStretch"
                  fluid
                  ref={this.props.benefitsRef}
                >
                  <Row className="BenefitsSection" noGutters>
                    <Col>
                      <Row noGutters>
                        <Col className="HomeInformationHeading2">
                          Benefits of working
                          <br />
                          with us
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col>
                          <Row className="BenefitsPoint" noGutters>
                            <Col
                              xs={2}
                              sm={2}
                              md={2}
                              className="HomeInformationIcon2"
                            >
                              <SVG5 />
                            </Col>
                            <Col xs={10} sm={10} md={10}>
                              <Row noGutters>
                                <Col className="BenefitsHeading">Rapid</Col>
                              </Row>
                              <Row noGutters>
                                <Col className="BenefitsText">
                                  We build using rapid construction technologies
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="BenefitsPoint" noGutters>
                            <Col
                              xs={2}
                              sm={2}
                              md={2}
                              className="HomeInformationIcon2"
                            >
                              <SVG6 />
                            </Col>
                            <Col xs={10} sm={10} md={10}>
                              <Row noGutters>
                                <Col className="BenefitsHeading">
                                  Technology-focused
                                </Col>
                              </Row>
                              <Row noGutters>
                                <Col className="BenefitsText">
                                  Bespoke software as standard in all our
                                  projects
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row className="BenefitsPoint" noGutters>
                            <Col
                              xs={2}
                              sm={2}
                              md={2}
                              className="HomeInformationIcon2"
                            >
                              <SVG7 />
                            </Col>
                            <Col xs={10} sm={10} md={10}>
                              <Row noGutters>
                                <Col className="BenefitsHeading">
                                  Full Spectrum
                                </Col>
                              </Row>
                              <Row noGutters>
                                <Col className="BenefitsText">
                                  One destination to handle your project from
                                  end-to-end
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="BenefitsPoint" noGutters>
                            <Col
                              xs={2}
                              sm={2}
                              md={2}
                              className="HomeInformationIcon2"
                            >
                              <SVG8 />
                            </Col>
                            <Col xs={10} sm={10} md={10}>
                              <Row noGutters>
                                <Col className="BenefitsHeading">
                                  Payment Plans
                                </Col>
                              </Row>
                              <Row noGutters>
                                <Col className="BenefitsText">
                                  We reduce barriers to build by working with
                                  you to structure payment plans so you can
                                  build more, faster
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Fragment>
            ) : (
              <Fragment>
                <Container
                  className="HomeMobileContentStretch"
                  style={{ height: "80vh", backgroundColor: "#F4F5F7" }}
                  fluid
                >
                  <Image src={backgroundLeft} className="HomeSideImage" />
                  <Row className="HomeCTA" noGutters>
                    <Col xs={1} sm={1} md={1} />
                    <Col xs={10} sm={10} md={10}>
                      <Row noGutters>
                        <Col className="HomeHeading">Origami</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeSubtitle">
                          Turning spaces into art in the simplest way possible
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} />
                  </Row>
                </Container>
                <Container
                  className="HomeMobileContentStretch ApproachSection"
                  fluid
                  ref={this.props.approachRef}
                >
                  <Row noGutters>
                    <Col className="HomeInformationHeading">Our Approach</Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ApproachPoint">
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG1 className="ContentSVG" />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Scope</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Fleshing out all aspects of your property development
                        </Col>
                      </Row>
                    </Col>
                    <Col className="ApproachPoint">
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG2 className="ContentSVG" />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Design</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Working with you to design the best use of your space
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="ApproachPoint">
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG3 className="ContentSVG" />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Execute</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Project management with military precision
                        </Col>
                      </Row>
                    </Col>
                    <Col className="ApproachPoint">
                      <Row noGutters>
                        <Col className="HomeInformationIcon">
                          <SVG4 className="ContentSVG" />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformation">Handover</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="HomeInformationText">
                          Delivery of the masterpiece
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
                <Image src={sideImage} className="HomeImage" />
                <Container
                  className="HomeMobileContentStretch ServicesSection"
                  style={{ backgroundColor: "#F4F5F7" }}
                  fluid
                  ref={this.props.servicesRef}
                >
                  <Row noGutters>
                    <Col className="HomeInformationHeading">
                      Services we offer
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col>
                      <Row noGutters>
                        <Col className="ServicesHeading">
                          &bull; Licensed Builder
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Registered Domestic Builders with years of experience
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">&bull; Mechanical</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Bespoke HVAC solutions for builders and residential
                          customers with a focus on sustainability
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">
                          &bull; Town Planning
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Approvals of your projects
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">&bull; Demolition</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          Rapid removal and recycling of old-world structures to
                          make way for the new
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesHeading">
                          &bull; Landscaping
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="ServicesText">
                          An engineered approach to foliage
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} />
                  </Row>
                </Container>
                <Container
                  className="HomeMobileContentStretch BenefitsSection"
                  fluid
                  ref={this.props.benefitsRef}
                >
                  <Row noGutters>
                    <Col className="HomeInformationHeading">
                      Mechanical Services
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="BenefitsText">
                      At Origami, we believe that everyone should live healthy,
                      comfortable lives in harmony with the environment. To
                      achieve this, we craft tailored heating, cooling and
                      ventilation solutions for each customer's unique
                      requirements. We specialise in creating beautiful,
                      sustainable spaces using cutting-edge green technologies.
                    </Col>
                  </Row>
                </Container>
                <Container
                  className="HomeMobileContentStretch BenefitsSection"
                  fluid
                  ref={this.props.benefitsRef}
                >
                  <Row noGutters>
                    <Col className="HomeInformationHeading">
                      Benefits of working
                      <br />
                      with us
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={2} sm={2} md={2} className="HomeInformationIcon2">
                      <SVG5 className="ContentSVG2" />
                    </Col>
                    <Col xs={10} sm={10} md={10}>
                      <Row noGutters>
                        <Col className="BenefitsHeading">Rapid</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="BenefitsText">
                          We build using rapid construction technologies
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={2} sm={2} md={2} className="HomeInformationIcon2">
                      <SVG6 className="ContentSVG2" />
                    </Col>
                    <Col xs={10} sm={10} md={10}>
                      <Row noGutters>
                        <Col className="BenefitsHeading">
                          Technology-focused
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col className="BenefitsText">
                          Bespoke software as standard in all our projects
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={2} sm={2} md={2} className="HomeInformationIcon2">
                      <SVG7 className="ContentSVG2" />
                    </Col>
                    <Col xs={10} sm={10} md={10}>
                      <Row noGutters>
                        <Col className="BenefitsHeading">Full Spectrum</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="BenefitsText">
                          One destination to handle your project from end-to-end
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={2} sm={2} md={2} className="HomeInformationIcon2">
                      <SVG8 className="ContentSVG2" />
                    </Col>
                    <Col xs={10} sm={10} md={10}>
                      <Row noGutters>
                        <Col className="BenefitsHeading">Payment Plans</Col>
                      </Row>
                      <Row noGutters>
                        <Col className="BenefitsText">
                          We reduce barriers to build by working with you to
                          structure payment plans so you can build more, faster
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default Home;
